<template>
    <div class="myCenter">
        <el-carousel height="166px" indicator-position="none">
            <el-carousel-item v-for="(item,index) in bannerList" :key="index">
                <img :src="item.imgPath" @click="toimgdetail(item)" style="width:100%;height:100%;" />
            </el-carousel-item>
        </el-carousel>
        <!-- <img class="myCenter_img" src="../../assets/mySelf/my-banner.png" alt /> -->
        <div class="myCenter_msg">
            <div>
                <p>{{ studyObj.continuityStudyDays }}</p>
                <p>学习天数</p>
            </div>
            <div>
                <p>{{ studyObj.mineCourseCount }}</p>
                <p>学习课程</p>
            </div>
            <div>
                <p>{{ studyObj.answerWrongCount }}</p>
                <p>做题总数</p>
            </div>
            <div>
                <p>{{ (studyObj.studyCourseLength / 3600).toFixed(2) }}</p>
                <p>学习总时长</p>
            </div>
        </div>
        <!-- <div class="myCenter_choice">
            <p class="p_title">提供多种免费学习工具，祝你快速成长！</p>
            <div class="choice_msg">
                <div class="choice_msg_list">
                   
                    <p style="cursor:pointer;" :class="['choice_msg_list_item', active == 0 ? 'active' : '']" @click="onSwith(0)">
                        <span class="spot"></span> 免费课程
                    </p>
                    <p style="cursor:pointer;" :class="['choice_msg_list_item', active == 1 ? 'active' : '']" @click="onSwith(1)">
                        <span class="spot"></span> 免费直播
                    </p>
                    <p style="cursor:pointer;" :class="['choice_msg_list_item', active == 2 ? 'active' : '']" @click="onSwith(2)">
                        <span class="spot"></span> 在线题库
                    </p>
                    <p style="cursor:pointer;" :class="['choice_msg_list_item', active == 3 ? 'active' : '']" @click="onSwith(3)">
                        <span class="spot"></span> 免费资料
                    </p>
                </div>
                <div class="choice_msg_image">
                    <div class="img-box" v-if="active == 0">
                        <img src="../../assets/mySelf/kecheng1.png" alt />
                        <img src="../../assets/mySelf/kecheng2.png" alt />
                    </div>
                    <div class="img-box" v-else-if="active == 1">
                        <img src="../../assets/mySelf/center-banner3.png" alt />
                        <img src="../../assets/mySelf/center-banner3.png" alt />
                    </div>
                    <div class="img-box" v-else-if="active == 2">
                        <img src="../../assets/mySelf/tiku1.png" alt />
                        <img src="../../assets/mySelf/tiku2.png" alt />
                    </div>
                    <div class="img-box" v-else>
                        <img style="width:515px;" src="../../assets/mySelf/ziliao1.png" alt />
                    </div>

                    <button style="cursor:pointer;" @click="chakan()">查看</button>
                </div>
            </div>
        </div> -->
        <div class="myCenter_recently">
            <p class="myCenter_recently_title">最近学习</p>
            <div class="myCenter_recently_list">
                <div class="myCenter_recently_list_item" v-for="(item,index) in buyList" :key="index">
                    <div class="myCenter_recently_list_item_left">
                        <img :src="item.coverPath" alt class="myCenter_recently_list_item_left_img" />
                        <div class="myCenter_recently_list_item_left_content">
                            <p>{{ item.packageName }}</p>
                            <p>
                                <span>到期时间：</span>
                                <span>{{ item.effectiveEtime }}</span>
                            </p>
                            <p>
                                <el-progress :percentage="item.studyCourseCount / item.classHourCount1 * 100" :show-text="false" :stroke-width="10"></el-progress>
                                <span>已学习：{{ item.studyCourseCount }}/{{ item.classHourCount1 }}课时</span>
                            </p>
                        </div>
                    </div>
                    <button class="myCenter_recently_list_item_right" style="cursor:pointer;" @click="jixuxuexi()">继续学习</button>
                </div>
                <!-- <div class="myCenter_recently_list_item">
                    <div class="myCenter_recently_list_item_left">
                        <img src alt class="myCenter_recently_list_item_left_img" />
                        <div class="myCenter_recently_list_item_left_content">
                            <p>中级零基础《初级会计实务》会计科目及借贷</p>
                            <p>
                                <span>2021-07-06 10:50</span>
                                <span>学习至2021年初级会计实务备考指导</span>
                            </p>
                            <p>
                                <el-progress :percentage="50" :show-text="false" :stroke-width="10"></el-progress>
                                <span>已学习：2/4课时</span>
                            </p>
                        </div>
                    </div>
                    <button class="myCenter_recently_list_item_right">继续学习</button>
                </div>-->
            </div>
            <div class="noMore" v-if="!buyList.length">暂无数据</div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { study_index, has_buy, banners,packageList } from "../../api/myself"
export default {
    name: 'myCenter',
    data() {
        return {
            userInfo: {},//JSON.parse(this.$store.state.userInfo),
            studyObj: {},
            buyList: [],
            active: 0,
            bannerList: []
        }
    },
    mounted() {
        this.userInfo = localStorage.getItem('setuserInfo');
        this.study_index();
        // this.has_buy();
        this.packageList();
        this.banners();
    },
    methods: {
        chakan() {
            var a = this.active;
            console.log(a);
            var path = "/";
            if (a == 0) {
                path = '/course';
            } else if (a == 1) {
                path = '/liveList';
            } else if (a == 2) {
                path = '/examination';
            } else if (a == 3) {
                path = '/datum';
            }
            let routeUrl = this.$router.resolve({
                path: path,
                query: {}
            });
            window.open(routeUrl.href, '_blank');
        },
        // 去banner详情
        toimgdetail(item) {
            window.open(item.linkUrl, '_blank');
        },
        banners() {
            banners().then(res => {
                console.log("轮播图");
                console.log(res);
                this.bannerList = res.data.data;

            })
        },
        jixuxuexi() {

            this.$router.push({
                path: '/myself/myClass',
                query: {}
            })
        },
        study_index() {
            study_index().then((res) => {
                if (res.data.code == 0) {
                    this.studyObj = res.data.data
                }
            })
        },
        // has_buy() {
        //     has_buy().then(res => {
        //         console.log(res);
        //         let dataList = res.data.data;
        //         if (dataList.length > 2) {
        //             dataList = res.data.data.splice(0, 2)
        //         }
        //         this.buyList = dataList;
        //     })
        // },
        packageList(){
            packageList().then(res=>{
                this.buyList = res.data.data;
            })
        },
        onSwith(idx) {
            this.active = idx
        }
    },
    computed: {
        ...mapGetters(['getUserInfo'])
    },
    watch: {
        getUserInfo(e, old) {
            console.log(e);
            this.userInfo = JSON.parse(e);
        }
    }
}
</script>

<style scoped>
.noMore {
    text-align: center;
    width: 100%;
    color: #999;
    font-size: 14px;
}
</style>